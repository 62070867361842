export const downloadFile = (filename, file) => {
  var element = document.createElement('a');
  element.setAttribute('href', file);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const parseYoutubeLink = (youtubeLink) => {
  if (youtubeLink.includes('watch?v=')) {
    return `https://www.youtube.com/embed/${youtubeLink.slice(youtubeLink.indexOf('?v=') + 3)}`;
  }
  if (youtubeLink.includes('youtu.be')) {
    return `https://www.youtube.com/embed/${youtubeLink.slice(
      youtubeLink.indexOf('youtu.be/') + 9
    )}`;
  }
};
